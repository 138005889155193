import { usePiletApi } from 'piral-core';
import { PiralNotificationTypes } from 'piral-notifications';
import React from 'react';

export const TestButtons = () => {
  const { showNotification, openSelectWindow, sendMessage } = usePiletApi();

  const isLocalhost = window.location.hostname === 'localhost';

  const showBrowserNotification = () => {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        new Notification('Benachrichtigung', {
          body: 'Dies ist eine Beispielbenachrichtigung.',
        });
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            // Wenn die Berechtigung erteilt wird, senden Sie die Benachrichtigung
            new Notification('Benachrichtigung', {
              body: 'Dies ist eine Beispielbenachrichtigung.',
            });
          }
        });
      }
    }
  };

  const showToast = (type: keyof PiralNotificationTypes) => {
    showNotification('Das ist für den Inhalt gedacht, etwas hat nicht funktioniert oder eben doch.', {
      title: 'Neue Toast Benachrichtigung',
      type,
    });
  };

  const showPopup = () => {
    openSelectWindow(new URL('http://localhost:1234/matent')).then(data => console.log(data));
  };

  const sendMessageToOpener = () => {
    sendMessage({ date: new Date(), jo: 'bla' });
  };

  if (isLocalhost) {
    return (
      <div className="neo-dropdown-menu">
        <button type="button">TEST</button>
        <div>
          <button type="button" onClick={showBrowserNotification}>
            Browser-Notification senden
          </button>
          <button type="button" onClick={() => showToast('error')}>
            Error-Toast senden
          </button>
          <button type="button" onClick={() => showToast('success')}>
            Success-Toast senden
          </button>
          <button type="button" onClick={showPopup}>
            Popup test
          </button>
          <button type="button" onClick={sendMessageToOpener}>
            Send Message
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
