export class SipMessageBus {
  private events: Record<string, ((data: Record<string, any>) => void)[]> = {};
  private returnSource = '';
  private returnOrigin = '';

  constructor() {
    window.addEventListener('message', (event: MessageEvent) => {
      if (!event.data || !event.data.source) return;
      if (event.data.source.includes('angular')) return;
      if (event.data.source.includes('piral')) return;
      if (event.data.source === 'neodymium') {
        console.log('selectWindowApi', 'command message received', event.data);
        this.returnOrigin = event.data.returnOrigin;
        this.returnSource = event.data.returnSource;
        if (event.data.selfClosing) {
          window.onblur = () => {
            const message = { source: this.returnSource, success: false, error: 'window_closed' };
            console.log('selectWindowApi', 'window blured, send close message to ' + this.returnOrigin, message);
            window.opener.postMessage(message, this.returnOrigin);
            window.close();
          }
        }
        console.log('selectWindowApi', 'send command message answer');
        window.opener.postMessage({ source: `neodymium_${this.returnSource}` }, this.returnOrigin);
        return;
      }
      this.handleReceivedMessage(event.data.source, event.data);
    });
  }

  public on(source: string, listener: (data: Record<string, any>) => void) {
    if (!this.events[source]) {
      this.events[source] = [];
    }
    this.events[source].push(listener);
  }

  public off(source: string, listenerToRemove: (data: Record<string, any>) => void) {
    if (!this.events[source]) {
      return;
    }
    this.events[source] = this.events[source].filter(listener => listener !== listenerToRemove);
    if (this.events[source].length === 0) {
      delete this.events[source];
    }
  }

  public once(source: string, listener: (data: Record<string, any>) => void): () => void {
    const remover = (data: Record<string, any>) => {
      listener(data);
      this.off(source, remover);
    };
    this.on(source, remover);
    return () => this.off(source, remover);
  }

  public handleReceivedMessage(source: string, data: Record<string, any>) {
    if (!this.events[source]) {
      return;
    }
    this.events[source].forEach(listener => listener(data));
  }

  public postMessage(data: Record<string, any>) {
    const dest = window.opener ? window.opener : window;
    const message = { source: this.returnSource, success: true, data };
    console.log('selectWindowApi', 'send select message to ' + this.returnOrigin, message);
    dest.postMessage(message, this.returnOrigin);
  }
}
