import React, { useState, useEffect, useRef } from 'react';
import { MenuRootItem, SipMenuItem } from '../../siplet-api';
import { PiletOnlyMenu } from '../PiletOnlyMenu/PiletOnlyMenu';
import { VersionInfo } from '../VersionInfo/VersionInfo';
import { ExtensionSlot } from 'piral-core';
import { useTranslate } from 'piral-translate';
import { useKeycloak } from '../../keycloak-api';
import { Breadcrumbs } from 'piral-breadcrumbs';

import './sidebar.css';

export const Sidebar = () => {
  const translate = useTranslate();
  const { authenticated } = useKeycloak();
  const dashboardItem: MenuRootItem = {
    route: '/',
    icon: require('../../assets/svgs/home.svg'),
    title: translate('apps'),
  };

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [manuallyToggled, setManuallyToggled] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = e => {
    e.stopPropagation();
    setManuallyToggled(true);
    setSidebarOpen(!isSidebarOpen);
  };

  const handleOutsideClick = e => {
    if (sidebarRef.current && !sidebarRef.current.contains(e.target) && isSidebarOpen && isSmallScreen) {
      setSidebarOpen(false);
      setManuallyToggled(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1024);
      if (window.innerWidth > 1024) {
        setSidebarOpen(true);
      } else {
        if (!manuallyToggled) {
          setTimeout(() => {
            setSidebarOpen(false);
          }, 500);
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    document.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isSidebarOpen, isSmallScreen]);

  return (
    <>
      <div ref={sidebarRef} id="application-sidebar" className={`neo-app-sidebar ${isSmallScreen ? (isSidebarOpen ? 'expanded' : 'collapsed') : 'expanded'}`}>
        <div className="neo-lurchi"></div>
        <div className="neo-sidebar-content scrollbar-hide">
          <nav>
            <ul>
              {authenticated && <SipMenuItem item={dashboardItem} />}
              <PiletOnlyMenu type="sidebar" />
            </ul>
          </nav>

          <div className="neo-sidebar-bottom">
            <div className="neo-sidebar-slot">
              <ExtensionSlot name="bottom-sidebar" />
              <VersionInfo />
            </div>
            <div className="neo-sidebar-imprint">
              <a href={translate('impressum-link')} target="_blank">
                {translate('impressum')}
              </a>
            </div>
          </div>
        </div>
      </div>

      {isSmallScreen && (
        <div className="neo-nav-bar">
          <button className="neo-nav-menu-button" aria-controls="application-sidebar" aria-label="Toggle navigation" onClick={toggleSidebar}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10 -10 30 30">
              <path stroke="currentColor" strokeLinecap="round" d="M0.5 0.5 h9 M0.5 5 h9 M0.5 9.5 h9" />
            </svg>
          </button>
          <div className="neo-nav-bar-breadcrumbs">
            <Breadcrumbs />
          </div>
        </div>
      )}
    </>
  );
};
