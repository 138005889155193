import * as React from 'react';
import { MenuRootItem, SipTileAndMenuApi, SipBreadcrumbItem } from './types';
import { SipTile } from './components/SipTile/SipTile';
import { MenuType } from 'piral-menu';
import { PiralPlugin } from 'piral-core';
import { SipMenuItem } from './components/SipMenuItem/SipMenuItem';
import { BreadcrumbSettings } from 'piral-breadcrumbs';
import { SipBreadcrumb } from './SipBreadcrumb';
import { SipBreadcrumbTitleStorage } from './SipBreadcrumbTitleStorage';
import { SipMessageBus } from './SipMessageBus';

export function createSipletApi(): PiralPlugin<SipTileAndMenuApi> {
  return context => {
    const breadcrumbTitleStorage = new SipBreadcrumbTitleStorage();
    const messageBus = new SipMessageBus();
    context.dispatch(state => ({
      ...state,
      breadcrumbTitleStorage,
      messageBus,
    }));
    return (api, target) => ({
      // Tiles im Dashboard
      registerSipTile(route: string, title: string, description: string, imgSource: string | null) {
        api.registerTile(route, () => <SipTile linkTo={route} title={title} description={description} imgSource={imgSource} />, {
          initialColumns: 2,
          initialRows: 2,
          resizable: true,
        });
      },

      unregisterSipTile(route: string) {
        api.unregisterTile(route);
      },

      // SipMenu (links)
      registerSipMenu(item: MenuRootItem, type: MenuType = 'sidebar') {
        api.registerMenu(item.route, () => <SipMenuItem item={item} />, { type });
      },

      unregisterSipMenu(item: MenuRootItem) {
        api.unregisterMenu(item.route);
      },

      registerSipBreadcrumb(item: SipBreadcrumbItem | BreadcrumbSettings) {
        if ('dynamicTitleKey' in item) {
          api.registerBreadcrumb({
            path: item.path,
            parent: item.parent,
            title: params => <SipBreadcrumb item={item} params={params} />,
          });
        } else {
          api.registerBreadcrumb(item);
        }
      },

      setBreadcrumbTitle(group: string, key: string, title: string) {
        breadcrumbTitleStorage.setTitle(group, key, title);
      },

      openSelectWindow(url: URL, options?: { debug?: boolean, height?: number, width?: number}): Promise<Record<string, any>> {
        return new Promise((resolve, reject) => {
          const height = options?.height ?? 800;
          const width = options?.width ?? 1024;
          const x = window.screenLeft + window.outerWidth / 2 - width / 2;
          const y = window.screenTop + window.outerHeight / 2 - height / 2;
          const selectWindow = window.open(url, 'select_dialog', `width=${width},height=${height},top=${y},left=${x}`);
          console.log('selectWindowApi', 'window opened');
          const debug = options?.debug ?? false;
          const returnSource = Math.random().toString(36).substring(2);
          const commListenerKey = `neodymium_${returnSource}`;
          let clearIntervalCounter = 0;
          let commInterval;
          const clearIntervalFunc = () => clearInterval(commInterval);
          const commListenerRemover = messageBus.once(commListenerKey, clearIntervalFunc);
          const retListenerRemover = messageBus.once(returnSource, data => {
            console.log('selectWindowApi', 'message from select window', data);
            if (data.success) {
              resolve(data.data);
            } else {
              reject(data.error);
            }
          });
          commInterval = setInterval(() => {
            clearIntervalCounter++;
            const message = {
              source: 'neodymium',
              returnOrigin: window.location.origin,
              returnSource,
              selfClosing: !debug,
            };
            selectWindow.postMessage(message, url.origin);
            console.log('selectWindowApi', 'send message with origin', message);
            if (clearIntervalCounter > 20) {
              console.log('selectWindowApi', 'counter to high');
              clearIntervalFunc();
              commListenerRemover();
              retListenerRemover();
              selectWindow.close();
              reject('no_communication');
            }
          }, 500);
        });
      },

      sendMessage(data: Record<string, any>) {
        messageBus.postMessage(data);
      },
    });
  };
}
