// preambel
import 'core-js/es/reflect';
import 'core-js/stable/reflect';
import 'core-js/features/reflect';
import 'core-js/proposals/reflect-metadata';
import 'zone.js';
import '@angular/compiler';
// other
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createInstance, Piral } from 'piral-core';
import { Dashboard } from 'piral-dashboard';
import { storage } from 'piral-core';
import { KeycloakProvider, useKeycloak } from './keycloak-api';
import { Loading } from './components/Loading/Loading';
import { getPlugins } from './plugins';
import { components } from './components/Layout/Layout';
import { errorComponents } from './components/Errors/Errors';

const AuthorizedApp = () => {
  const { keycloak, loading } = useKeycloak();

  React.useEffect(() => {
    const handleDropDownClick = (event: PointerEvent) => {
      const openMenus = document.querySelectorAll('.neo-dropdown-menu.open');
      if (openMenus && openMenus.length) {
        for (const openMenu of openMenus) {
          openMenu.classList.remove('open');
        }
      } else if (event.target instanceof HTMLElement && event.target.matches('.neo-dropdown-menu *')) {
        let element = event.target;
        do {
          if (element.matches('.neo-dropdown-menu')) {
            element.classList.toggle('open');
            break;
          }
          element = element.parentElement;
        } while (element && element !== document.body);
      }
    };

    document.addEventListener('click', handleDropDownClick);
    return () => {
      document.removeEventListener('click', handleDropDownClick);
    };
  }, []);

  if (loading) {
    return <Loading />;
  }

  // Sprache kommt von keycloak und wird einmalig gesetzt
  let locale: string = keycloak.idTokenParsed?.['locale'];
  if (!locale) {
    locale = navigator.language ?? 'de';
    if (locale.indexOf('-') > 0) {
      locale = locale.substring(0, locale.indexOf('-'));
    }
  }
  storage.setItem('locale', locale);

  const appInstance = createInstance({
    plugins: getPlugins(),
    requestPilets: () => {
      const feedUrl = keycloak.authServerUrl.includes('keycloak.dev.sip.de') ? '/devfeed' : '/feed';
      return fetch(feedUrl, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      })
        .then(res => res.json())
        .then(res => res.items);
    },
    state: {
      components,
      errorComponents,
      routes: {
        '/': Dashboard,
      },
    },
  });
  (window as any).keycloakGetIdToken = () => {
    return JSON.stringify(keycloak.idTokenParsed);
  };

  (window as any).keycloakGetAccessToken = () => {
    return keycloak.token;
  };

  return <Piral instance={appInstance} />;
};

const App = () => {
  return (
    <KeycloakProvider>
      <React.Suspense fallback={<Loading />}>
        <AuthorizedApp />
      </React.Suspense>
    </KeycloakProvider>
  );
};

const root = createRoot(document.querySelector('#app'));
root.render(<App />);
