import * as React from 'react';
import { useAction } from 'piral-core';
import { useTranslate } from 'piral-translate';
import { SnowLevels } from '../../types';

import './snowfall-button.css';

export function isChristmasTime(): boolean {
  const today = new Date();
  // von Nikolaus (06.12.)
  const inDecember = today.getTime() > new Date(today.getFullYear(), 11, 6).getTime();
  // bis inkl. Hl. 3 König (06.01.)
  const inJanuary = today.getTime() < new Date(today.getFullYear(), 0, 7).getTime();
  return inDecember || inJanuary;
}

const snowFlake = require('../../../assets/svgs/snowflake.svg');

export const SnowfallButton: React.FC = () => {
  const setSnowLevel = useAction('setSnowLevel');
  const levels: SnowLevels[] = ['0', '1', '2', '3'];
  const translate = useTranslate();

  if (!isChristmasTime()) {
    return null;
  }

  return (
    <div className="neo-dropdown-menu neo-snowfall-menu">
      <button type="button">
        <img alt="snowflake" src={snowFlake} />
      </button>
      <div>
        {levels.map(level => (
          <button type="button" onClick={() => setSnowLevel(level)}>
            <img alt="snowflake" src={snowFlake} />
            {translate('stufe') + ` ${level}`}
          </button>
        ))}
      </div>
    </div>
  );
};
